<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mclase-modals
			:modelName="modelName"
		></mclase-modals>
	</div>
</template>

<script>
import mclaseActions from './MclaseActions';
import mclaseModals from './MclaseModals.vue';

export default {
	name: 'mclase-grid',
	components: {
		'mclase-modals': mclaseModals
	},
	data() {
		return {
			modelName: 'mclase',
			actions: mclaseActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
